<template>
    <div>
        <div class="card mt-3">
            <div class="card-body mt-0 pt-0 pb-0">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0">
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'CampaignList' }">Campaigns</router-link>
                        </li>
                        <li class="breadcrumb-item" aria-current="page" v-if="this.campaign">></li>
                        <li class="breadcrumb-item active text-color-normal" aria-current="page" v-if="this.campaign"> 
                            <span type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-reference="parent">
                                {{ this.campaign.name }} 
                                <i class="mdi mdi-arrow-down"></i>
                            </span>
                            <div class="dropdown-menu" aria-labelledby="dLabel">
                                <a 
                                    class="dropdown-item" 
                                    v-for="(item) in $store.state.campaign.items" 
                                    :key="item.id"
                                    :class="{ active: campaign_id == item.id, 'cursor-pointer': campaign_id != item.id }"
                                    @click="changeCampaign(item)"
                                >
                                        <i class="mdi mdi-check-bold" v-if="campaign_id == item.id"></i>
                                        {{ item.name }}
                                </a>
                            </div>
                        </li>
                    </ol>
                </nav>
            </div>
        </div>

        <div class="card mt-3">
            <div class="tab-pane show active">
                <ul class="nav nav-tabs nav-justified nav-bordered mb-3">
                    <li class="nav-item">
                        <router-link :to="{ name: 'Dashboard', params: { campaign_id } }" class="nav-link" :class="{ active: is_active('/dashboard') }" data-toggle="tab" aria-expanded="false">
                            <i class="mdi mdi-chart-line d-md-none d-block"></i>
                            <span class="d-none d-md-block">Dashboard</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'Hits', params: { campaign_id } }" class="nav-link" :class="{ active: is_active('/hits') }" data-toggle="tab" aria-expanded="false">
                            <i class="mdi mdi-chart-line d-md-none d-block"></i>
                            <span class="d-none d-md-block">Hits</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'Links', params: { campaign_id } }" class="nav-link" :class="{ active: is_active('/links') }" data-toggle="tab" aria-expanded="false">
                            <i class="mdi mdi-link d-md-none d-block"></i>
                            <span class="d-none d-md-block">Links</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'Tags', params: { campaign_id } }" class="nav-link" :class="{ active: is_active('/tags') }" data-toggle="tab" aria-expanded="false">
                            <i class="mdi mdi-tag-multiple-outline d-md-none d-block"></i>
                            <span class="d-none d-md-block">Tags</span>
                        </router-link>
                    </li>
                </ul>
            </div>

            <div class="card-body mt-0 pt-0">
                <transition :name="transitionName" mode="out-in"><router-view class="child-view"></router-view> </transition>
            </div>
        </div>
    </div>
</template>

<script>
import { getCampaign } from '@/plugins/hasura/queries'

export default {
    name: 'IndexLayout',
	data() {
		return {
            item: null,
			transitionName: 'slide-right',
			transitionPage: 1,
		};
    },
    computed: {
        campaign_id() {
            return this.$route.params.campaign
        },
        campaign() {
            return this.$store.state.campaign.items.find((item) => item.id == this.campaign_id)
        }
    },
	beforeRouteUpdate(to, from, next) {
		let dict = {
			'/links': 1,
			'/campaigns': 10,
			'/tags': 20,
		};
		let toWeight = typeof dict[to.path] != 'undefined' ? dict[to.path] : 0;
		let fromWeight = typeof dict[from.path] != 'undefined' ? dict[from.path] : 0;

		this.transitionName = toWeight < fromWeight ? 'slide-left' : 'slide-right';
		next();
    },
    async beforeMount() {
        $('#campaign_select').select2();
    },
    methods: {
        is_active(route) {
            return this.$route.path.indexOf(route) > 0
        },
        changeCampaign(item) {
            this.$router.push({ params: { campaign: item.id } })
        }
    }
}
</script>

<style>
.main-card {
    background-color: #FDFDFF;
}
.text-color-normal {
    color: #6c757d !important;
}
</style>